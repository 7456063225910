@import "modules/typography-variables";

html {
  scroll-behavior: smooth;
}

body {
  background: #fff;
  font: normal 100%/1.5 $body-font;
  color: $black;
  font-weight: 400;
  margin: 0;
  text-align: left;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.wrapper {
  width: 100%;
  max-width: 95em; /*1520/16*/
  padding: 0 60px;
  margin: 0 auto;

  @include MQ(1259) {
    padding: 0 30px;
  }

  @include MQ(767) {
    padding: 0 20px;
  }
}
// .content {
// }
// p {
//   word-break: break-word;
//   word-wrap: break-word;
// }
