.legal-privacy-wrap {
  text-align: left;
  word-break: break-word;

  @include atLarge {
    margin-top: 50px;
  }

  h2 {
    @include font-size(30px);
    letter-spacing: 0.05rem;
    font-weight: 600;
    padding-bottom: 15px;

    &--text-white {
      color: $white;
    }

    @include atMedium {
      @include font-size(36px);
    }
    @include atLarge {
      @include font-size(46px);
    }
  }

  h3 {
    @include font-size(20px);
    letter-spacing: 0.05rem;
    font-weight: 600;
    padding-bottom: 15px;

    &--text-white {
      color: $white;
    }

    @include atMedium {
      @include font-size(24px);
    }
    @include atLarge {
      @include font-size(30px);
    }
  }

  ul {
    font-size: 1.125rem;
    margin: 20px 0;
    list-style-type: disc;
    padding-left: 25px;

    @include atMedium {
      font-size: 1.25rem;
    }
    @include atXlarge {
      font-size: 1.375rem;
    }

    // &:last-child {
    //   margin-bottom: 0;
    // }
  }

  a {
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px solid rgba($black, 0.8);

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid rgba($black, 0);
    }
  }
}
