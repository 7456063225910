// achievement
.achievement {
  max-width: 513px;
  margin: 50px auto;
  // border: 2px solid rgba($grey, 0.5);
}

// investments
.investments {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 20px;

  @include atSmall {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }

  @include atLarge {
    grid-template-columns: repeat(5, minmax(100px, 1fr));
    gap: 30px;
  }
}
.sector {
  box-shadow: 0 0 10px 1px rgba($grey, 0.5);
  transition: all 0.3s ease-in-out;
  padding: 30px 10px 10px;

  @include atMedium {
    padding: 50px 10px 30px;
  }

  &:hover {
    transform: translateY(-10px);
  }

  .icon {
    max-width: 72px;
    border-radius: 5px;
    padding: 5px;
    overflow: hidden;
    margin: auto;
    background-color: $primary;

    @include atMedium {
      max-width: 128px;
    }
  }
  .text {
    padding: 20px 0;
    color: $black;

    @include atMedium {
      font-size: 1.125rem;
    }
  }
}

// blockchain
.block-chain-wrap {
  @include atLarge {
    display: flex;
    align-items: center;
    background: rgba($black, 0.8);
  }
  .block-chain-image {
    max-width: 300px;
    flex-basis: 100%;
    margin: auto;

    @include atLarge {
      min-width: 300px;
      margin: unset;
    }
  }
  .block-chain-text {
    text-align: center;
    padding-top: 30px;

    @include atLarge {
      padding: 0 0 0 30px;
      text-align: left;
    }

    .block-heading {
      margin-bottom: 20px;

      @include atLarge {
        text-align: left !important;
      }
    }
    .text p {
      color: $white;
    }
    .button {
      text-align: left;
      margin-top: 20px;
    }
  }
}

// paradigm
.paradigm-btn-wrap {
  text-align: center;
  margin-top: 80px;

  .button {
    margin: 5px;
  }
}

// films
.films {
  margin-bottom: 50px;

  @include atSmall {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 50px;
  }
  @include atLarge {
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    gap: 30px;
  }
}
.film {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @include atSmall {
    margin-bottom: 0;
  }

  .film-content,
  .film-content:hover {
    text-decoration: none;
  }

  .poster-img {
    margin-bottom: 20px;
  }
  .imdb-rating {
    // display: none;
    margin-top: 15px;
    text-align: center;
  }
}

// press-articles
.press-articles {
  padding-top: 30px;

  @include atSmall {
    padding-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 50px;
  }
  @include atMedium {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    gap: 80px;
  }
}
.press-article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  border: 2px solid rgba($white, 0.2);
  border-radius: 5px;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    text-decoration: none;
    border: 2px solid rgba($white, 0.8);
  }

  @include atSmall {
    margin-bottom: 0;
  }

  .press-logo {
    max-width: 250px;
    margin: auto;
  }

  .text {
    padding-top: 30px;

    p {
      color: $white;
    }
  }
}

// page form
.form-section {
  position: relative;
}
