.common-wrap {
  margin-top: 30px;
  text-align: center;

  @include atSmall {
    margin-top: 40px;
  }

  @include atMedium {
    margin-top: 50px;
  }

  @include atLarge {
    margin-top: 60px;
  }
}
