.cv-block {
  border: 1px solid rgba($black, 0.2);
  padding: 20px;

  @include atLarge {
    display: flex;
    align-items: center;
  }
  @include atXXlarge {
    padding: 0;
  }

  .cv-image {
    max-width: 300px;
    flex-basis: 100%;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    img {
      object-fit: cover;
    }

    @include atXlarge {
      min-width: 550px;
      margin: unset;
      border-radius: 0;
      flex: 1;
    }
  }
  .cv-text {
    text-align: center;
    padding-top: 30px;

    @include atLarge {
      padding: 0 30px 0 30px;
      text-align: left;
    }
    .cv-social-media {
      text-align: center;
      margin-top: 20px;

      @include atLarge {
        text-align: left;
      }

      a {
        width: 36px;
        height: 36px;
        display: inline-block;
        margin: 3px;
        border: 1px solid rgba($black, 0.2);
        padding: 5px;
        text-align: center;

        &:hover {
          border: 1px solid rgba($black, 0.5);
        }

        i {
          font-size: 24px;
          line-height: 1.2;
        }
      }
    }
  }
}

// gallery
.gallery-grid {
  column-count: 1;
  column-gap: 5px;

  @include atSmall {
    column-count: 2;
    gap: 5px;
  }

  @include atMedium {
    column-count: 3;
  }

  @include atXlarge {
    column-count: 4;
  }

  a {
    display: inline-block;
    margin: 0 0 5px;
    width: 100%;
  }
  img {
    transition: 0.8s opacity;
  }
  &:hover img {
    opacity: 0.3;
  }
  &:hover img:hover {
    opacity: 1;
  }
}
