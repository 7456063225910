.mobile-navbtn {
  position: absolute;
  font-size: 1.7rem;
  width: 40px;
  line-height: 1.6;
  top: 15px;

  i {
    color: $primary;
  }

  @include atMedium {
    display: none;
  }
}

.bg-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  transition: 0.2s all ease;
  opacity: 0;
}
.bg-overlay.active {
  z-index: 10;
  opacity: 1;
}

// mobile nav

.mobile-nav {
  background-color: $primary;
  font-family: $second-font;

  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 20;
  height: 100%;
  max-width: 300px;
  width: 100%;
  overflow-y: scroll;
  left: -100%;
  transition: 0.3s all ease;

  @include atMedium {
    left: -100% !important;
  }
}
.mobile-nav.active {
  left: 0;
}
.mobile-nav ul {
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  // @include atMedium {
  //   margin: auto;
  //   flex-direction: row;
  //   justify-content: end;
  // }
}
.mobile-nav ul li {
  @include font-size(22px);
  font-weight: 700;
  position: relative;

  // @include atMedium {
  //   @include font-size(20px);
  // }
}
.mobile-nav ul li.current_page_item a {
  color: $primary;
}
.mobile-nav ul li a {
  padding: 15px;
  display: block;
  color: $white;
  text-decoration: none;

  // @include atMedium {
  //   // padding: 0;
  //   padding: 0 12px;
  //   color: $primary;

  //   &:hover {
  //     color: $accent;
  //   }
  // }
}

// main nav
.main-nav {
  background-color: $primary;
  font-family: $second-font;

  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 20;
  height: 100%;
  max-width: 300px;
  width: 100%;
  overflow-y: scroll;
  left: -100%;
  transition: 0.3s all ease;

  @include atMedium {
    background-color: transparent;
    position: relative;
    top: auto;
    left: 0;
    max-width: 100%;
    height: auto;
    overflow-y: hidden;
  }
}
.main-nav.active {
  left: 0;
}
.main-nav ul {
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @include atMedium {
    margin: auto;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.main-nav ul li {
  @include font-size(22px);
  font-weight: 700;
  position: relative;

  @include atMedium {
    @include font-size(20px);
    padding: 0 12px;
  }
}
.main-nav ul li.current_page_item a {
  color: $black;
  border-bottom: 1px solid $black;
}
.main-nav ul li a {
  padding: 15px;
  display: block;
  color: $white;
  text-decoration: none;

  @include atMedium {
    // padding: 0;
    // padding: 0 12px;
    padding: 0;
    color: $primary;
    border-bottom: 1px solid transparent;

    &:hover {
      color: $black;
      border-bottom: 1px solid $primary;
    }
  }
}
