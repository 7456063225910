html {
}
.clearfix {
}
*::-moz-selection {
    background: none repeat scroll 0 0 rgb(23, 119, 175);
    color: rgb(250, 250, 250);
    text-shadow: none;
    font-weight: 300;
}
button, input[type="button"], input[type="reset"], input[type="submit"] {
}
input:-moz-placeholder {
    font-size: 0.875em;
    line-height: 1.4;
}
.ie7 img, .iem7 img {
}
input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
}
input[type="search"] {
    box-sizing: content-box;
}
button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0 none;
    padding: 0;
}
p {
    /*-moz-hyphens: auto;*/
}
