.bgvideoWrap {
  // max-height: calc(100vh - 143px);
  overflow: hidden;
  position: relative;
  background: $blue;
  min-height: 500px;
  max-height: 500px;

  @include atSmall {
    min-height: 720px;
    max-height: 720px;
  }

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%;
  }

  .videoContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .videoBgImg {
      height: 100%;
      width: 100%;
      background: rgba(#000, 0.7);
      background-size: cover;
      position: relative;

      .videoOverlay {
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.5);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
      }
      .textContent {
        position: relative;
        z-index: 3;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100%;
        text-align: center;
        color: $white;
        line-height: 1.3;
      }
    }
  }
  .bgVideo {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    video {
      height: auto;
      left: 50%;
      min-height: 101%;
      min-width: 100%;
      position: absolute;
      top: 50%;
      width: auto;
      z-index: 1;
      transform: translate(-50%, -50%);
      // max-height: 101%;
      max-width: unset !important;
    }
  }

  .sliderTextWrap {
    position: relative;
    z-index: 3;
    margin: 0 auto;
    max-width: 100%;

    width: 80%;
    text-align: center;
    color: $white;
    line-height: 1.3;

    .iconImageWrap {
      max-width: 128px;
      margin: 0 auto 20px;

      @include atSmallMedium {
        max-width: 256px;
      }
    }

    .content {
      // @include font-size(48px);
      // color: $white;
      // font-weight: 700;
      margin-bottom: 20px;

      // @include MQ(767) {
      //   @include font-size(10px);
      // }

      .mainText {
        @include font-size(30px);
        color: $white;
        font-weight: 700;
        margin-bottom: 10px;

        @include atMedium {
          @include font-size(48px);
          margin-bottom: 15px;
        }
        p {
          font-weight: 700;
        }
      }
      .subText {
        @include font-size(24px);
        color: $white;
        font-weight: 300;
        margin-bottom: 30px;

        @include atMedium {
          @include font-size(36px);
          // margin-bottom: 10px;
        }
        p {
          font-weight: 300;
        }
      }
    }
  }
  .slideBtnWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .sliderBtn {
      display: inline-block;
      margin: 5px;
      padding: 12px 20px 10px;
      @include font-size(16px);

      color: $white;
      line-height: 1;
      text-decoration: none;
      text-align: center;
      // border-radius: 40px;
      border-radius: 10px;

      @include atMedium {
        padding: 18px 40px 15px;
        @include font-size(24px);
      }

      i {
        margin-left: 10px;
      }

      &.btnBgSolid {
        background: $primary;

        &:hover {
          color: $primary;
          box-shadow: inset 0 -6em 0 0 $white;
        }
      }
      &.btnBgTransparent {
        background: transparent;
        border: 1px solid $white;

        &:hover {
          // background: $white;
          box-shadow: inset 0 -6em 0 0 $white;
          color: $primary;
        }
      }
    }
  }
  // }
}

.slideOverVideo {
  .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    // opacity: 0;
    // visibility: hidden;

    .owl-prev,
    .owl-next {
      position: absolute;
      transform: translateY(-50%);
      margin-top: -10px;
      opacity: 0.7;
      transition: 0.3s all ease;
      border: 0;
      outline: none;
      color: $white;

      span {
        font-family: "Font Awesome 5 Free";
        font-size: 40px;
        color: $white;
      }

      &:hover {
        opacity: 1;
        color: $white;
      }
    }
    .owl-prev {
      left: 10px;
    }
    .owl-next {
      right: 10px;
    }
  }
}

.banner-heading {
  font-size: 1.5rem;
  color: $white;
  margin-bottom: 20px;
  font-weight: 700;

  @include atSmall {
    font-size: 2rem;
  }

  @include atMedium {
    font-size: 3rem;
  }
}
