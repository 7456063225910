// Google Fonts
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@300;400;500;700&display=swap");

// Font Variables
$body-font: "Poppins", Arial, Helvetica, sans-serif;
$second-font: "Raleway", sans-serif;

// Colour Variables
$black: #111;
$white: #fff;
$blue: #223c6b;
$grey: #8d8d8d;

$primary: #223c6b;
$accent: #212735;
$highlight: #7696ff;
$error: #ff0000;
$success: #2b9303;
