.page-section {
  padding: 50px 0;

  // @include atSmall {
  //   padding: 50px 0;
  // }
  @include atMedium {
    padding: 80px 0;
  }

  &--divider {
    position: relative;

    &::before {
      position: absolute;
      width: 100px;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      content: "";
      display: block;
      height: 3px;
      background-color: $primary;
    }
  }

  &--bg-grey {
    background-color: rgba($grey, 0.05);
  }
  &--bg-black {
    background-color: rgba($black, 1);
  }
  &--no-b-padding {
    padding-bottom: 0;
  }
  &--no-t-padding {
    padding-top: 0;
  }
  &--no-padding {
    padding: 0;
  }
  &--b-n-margin {
    margin-bottom: -1.5rem;
  }

  &--blockchain {
    position: relative;
    background: linear-gradient(rgba($black, 0.8), rgba($black, 0.8)),
      url("../images/bg/block-chain-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &--filmproduction {
    position: relative;
    background: linear-gradient(rgba($black, 0.8), rgba($black, 0.8)),
      url("../images/bg/flim-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &--press {
    position: relative;
    background: linear-gradient(rgba($black, 0.8), rgba($black, 0.8)),
      url("../images/bg/press-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.page-section p {
  font-size: 1.125rem;
  margin-bottom: 20px;

  @include atMedium {
    font-size: 1.25rem;
  }
  @include atXlarge {
    font-size: 1.375rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
