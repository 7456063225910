// books
.books {
  @include atSmallMedium {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 50px;
  }
}
.book {
  position: relative;
  display: block;
  box-shadow: 0 0 10px 1px rgba($grey, 0.5);
  margin-bottom: 50px;
  padding: 20px 20px 52px;
  text-decoration: none;

  &:last-of-type {
    margin-bottom: 0;
  }
  &:hover {
    text-decoration: none !important;

    .book-image img {
      transform: scale(1.04);
    }
  }

  @include atSmallMedium {
    margin-bottom: 0;
    padding: 30px 20px 52px;
  }

  .book-image {
    overflow: hidden;

    img {
      transition: all 0.3s ease-in-out;
    }
  }

  .book-heading {
    margin-top: 30px;
  }
  .about-book {
    margin: 30px 0;
  }
  .book-readmore {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

// single book
.single-book {
  text-align: center;
  max-width: 1200px;
  margin: auto;
  //   padding: 20px;

  @include atSmall {
    box-shadow: 0 0 10px 1px rgba($grey, 0.5);
    padding: 20px 30px 30px;
  }
  @include atMedium {
    padding: 20px 50px 50px;
  }
  @include atMedium {
    padding: 20px 80px 80px;
  }

  .book-image {
    margin-bottom: 20px;
  }

  .about-book {
    padding: 30px 0;
  }

  .order-button {
    font-size: 1.5rem;
    padding: 15px 30px;
    text-transform: uppercase;
  }
}
