.headline {
  text-align: center;
  @include font-size(30px);
  font-weight: 600;
  // text-transform: uppercase;
  letter-spacing: 0.05rem;
  line-height: 1.2;

  // @include gradient-text(270deg, $grey, 100%);

  &--text-white {
    color: $white;
  }

  &--text-left {
    text-align: left;
  }

  @include atMedium {
    @include font-size(38px);
  }
  @include atLarge {
    @include font-size(56px);
    // line-height: 1.2;
  }
}
.subline {
  text-align: center;
  @include font-size(20px);
  // text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: 500;

  &--mt-10 {
    margin-top: 10px;
  }

  &--text-white {
    color: $white;
  }

  @include atMedium {
    @include font-size(24px);
  }
  @include atLarge {
    @include font-size(30px);
  }
}

.headline-3 {
  text-align: center;
  @include font-size(20px);
  // text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: 500;

  &--text-white {
    color: $white;
  }

  @include atMedium {
    @include font-size(22px);
  }
  @include atLarge {
    @include font-size(26px);
  }
}
