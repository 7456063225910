.contact-wrap {
  // padding: 20px;
  @include atLarge {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;

    box-shadow: 0 0 10px 1px rgba($grey, 0.5);
  }
}
.contact-tab {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &:first-child {
    background-color: rgba($grey, 0.1);
  }

  @include atLarge {
    max-width: 50%;
    flex-basis: 100%;
    padding: 30px;
  }
}
.address {
  padding: 30px 0 0 0;
}
.contact-social-icons {
  display: flex;
  margin-top: 30px;
  justify-content: center;

  li {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    a {
      width: 32px;
      // display: block;
      text-align: center;
      color: $grey;

      &:hover {
        color: $black;
      }
    }

    i {
      font-size: 1.5rem;
    }
  }
}
.contact-form-wrap {
  padding: 30px 0;
  border-top: 1px solid rgba($grey, 0.3);

  @include atLarge {
    border-top: none;
  }

  .contact-form {
    max-width: 700px;
    padding: 30px 0 0;
    margin: auto;

    @include atXXlarge {
      padding: 30px 30px 0;
    }
  }
}
