.button {
  display: inline-block;
  padding: 10px 15px;
  background-color: $blue;
  border: 2px solid $blue;
  color: $white;
  border-radius: 3px;
  @include font-size(18px);
  transition: all 0.3s ease;

  i {
    margin-left: 10px;
  }

  &:hover {
    text-decoration: none;
    // background-color: $white;
    color: $blue;

    box-shadow: inset 0 -6em 0 0 $white;
  }

  &--border {
    background-color: transparent;
    border: 2px solid $blue;
    color: $blue;

    &:hover {
      text-decoration: none;
      // background-color: $blue;
      color: $white;

      box-shadow: inset 0 -6em 0 0 $blue;
    }
  }
}
