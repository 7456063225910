/*-HTML5 Reset :: style.css---------------------------------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@300;400;500;700&display=swap");
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img,
object,
embed {
  max-width: 100%;
}

img {
  /*display:block;*/
  vertical-align: bottom;
}

/* force a vertical scrollbar to prevent a jumpy page */
/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
}

.ie7 input[type="checkbox"] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

li.clearfix {
  display: list-item;
}

/*Hide these rules from IE-mac */
* html .clearfix {
  height: 1%;
}

.clearfix {
  *zoom: 1;
}

/*-End HTML5 Reset :: style.css------------------------------------------------------------------------------------------*/
.hidden, .invisible {
  visibility: hidden;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.boldcase {
  font-weight: 700;
}

.normalcase {
  font-weight: 400;
}

.textdec-underline {
  text-decoration: underline;
}

.textdec-normal, a {
  text-decoration: none;
}

.Text-Idnt {
  text-indent: -99999px;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
}

img.floatleft {
  float: left;
  margin: 0 10px 0 0;
}

img.floatright {
  float: right;
  margin: 0 0 0 10px;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

a {
  color: #000;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

a:hover {
  text-decoration: underline;
}

.noPaddingTop {
  padding-top: 0 !important;
}

.noPaddingBottom {
  padding-bottom: 0 !important;
}

.noPaddingLeft {
  padding-left: 0 !important;
}

.noPaddingRight {
  padding-right: 0 !important;
}

@keyframes smooth-entry {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1.8em);
    -moz-transform: translateY(-1.8em);
    -ms-transform: translateY(-1.8em);
    -o-transform: translateY(-1.8em);
    transform: translateY(-1.8em);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes smooth-entry-revers {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1.8em);
    -moz-transform: translateY(1.8em);
    -ms-transform: translateY(1.8em);
    -o-transform: translateY(1.8em);
    transform: translateY(1.8em);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

/* Mixin to create vertical, horizontal or radial gradient */
html {
  scroll-behavior: smooth;
}

body {
  background: #fff;
  font: normal 100%/1.5 "Poppins", Arial, Helvetica, sans-serif;
  color: #111;
  font-weight: 400;
  margin: 0;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  width: 100%;
  max-width: 95em;
  /*1520/16*/
  padding: 0 60px;
  margin: 0 auto;
}

@media (max-width: 78.6875em) {
  .wrapper {
    padding: 0 30px;
  }
}

@media (max-width: 47.9375em) {
  .wrapper {
    padding: 0 20px;
  }
}

.site-header {
  background-color: #fff;
  padding: 5px 20px;
  box-shadow: 0 0 5px 2px #8d8d8d;
  position: sticky;
  top: 0;
  z-index: 5;
}

.site-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  max-width: 230px;
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 63.125em) {
  .logo {
    max-width: 350px;
    margin: 0;
    padding: 0;
  }
}

.mobile-navbtn {
  position: absolute;
  font-size: 1.7rem;
  width: 40px;
  line-height: 1.6;
  top: 15px;
}

.mobile-navbtn i {
  color: #223c6b;
}

@media (min-width: 50em) {
  .mobile-navbtn {
    display: none;
  }
}

.bg-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  transition: 0.2s all ease;
  opacity: 0;
}

.bg-overlay.active {
  z-index: 10;
  opacity: 1;
}

.mobile-nav {
  background-color: #223c6b;
  font-family: "Raleway", sans-serif;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 20;
  height: 100%;
  max-width: 300px;
  width: 100%;
  overflow-y: scroll;
  left: -100%;
  transition: 0.3s all ease;
}

@media (min-width: 50em) {
  .mobile-nav {
    left: -100% !important;
  }
}

.mobile-nav.active {
  left: 0;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.mobile-nav ul li {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 700;
  position: relative;
}

.mobile-nav ul li.current_page_item a {
  color: #223c6b;
}

.mobile-nav ul li a {
  padding: 15px;
  display: block;
  color: #fff;
  text-decoration: none;
}

.main-nav {
  background-color: #223c6b;
  font-family: "Raleway", sans-serif;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 20;
  height: 100%;
  max-width: 300px;
  width: 100%;
  overflow-y: scroll;
  left: -100%;
  transition: 0.3s all ease;
}

@media (min-width: 50em) {
  .main-nav {
    background-color: transparent;
    position: relative;
    top: auto;
    left: 0;
    max-width: 100%;
    height: auto;
    overflow-y: hidden;
  }
}

.main-nav.active {
  left: 0;
}

.main-nav ul {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

@media (min-width: 50em) {
  .main-nav ul {
    margin: auto;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.main-nav ul li {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 700;
  position: relative;
}

@media (min-width: 50em) {
  .main-nav ul li {
    font-size: 20px;
    font-size: 1.25rem;
    padding: 0 12px;
  }
}

.main-nav ul li.current_page_item a {
  color: #111;
  border-bottom: 1px solid #111;
}

.main-nav ul li a {
  padding: 15px;
  display: block;
  color: #fff;
  text-decoration: none;
}

@media (min-width: 50em) {
  .main-nav ul li a {
    padding: 0;
    color: #223c6b;
    border-bottom: 1px solid transparent;
  }
  .main-nav ul li a:hover {
    color: #111;
    border-bottom: 1px solid #223c6b;
  }
}

.headline {
  text-align: center;
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  line-height: 1.2;
}

.headline--text-white {
  color: #fff;
}

.headline--text-left {
  text-align: left;
}

@media (min-width: 50em) {
  .headline {
    font-size: 38px;
    font-size: 2.375rem;
  }
}

@media (min-width: 63.125em) {
  .headline {
    font-size: 56px;
    font-size: 3.5rem;
  }
}

.subline {
  text-align: center;
  font-size: 20px;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
  font-weight: 500;
}

.subline--mt-10 {
  margin-top: 10px;
}

.subline--text-white {
  color: #fff;
}

@media (min-width: 50em) {
  .subline {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

@media (min-width: 63.125em) {
  .subline {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

.headline-3 {
  text-align: center;
  font-size: 20px;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
  font-weight: 500;
}

.headline-3--text-white {
  color: #fff;
}

@media (min-width: 50em) {
  .headline-3 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

@media (min-width: 63.125em) {
  .headline-3 {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

.button {
  display: inline-block;
  padding: 10px 15px;
  background-color: #223c6b;
  border: 2px solid #223c6b;
  color: #fff;
  border-radius: 3px;
  font-size: 18px;
  font-size: 1.125rem;
  transition: all 0.3s ease;
}

.button i {
  margin-left: 10px;
}

.button:hover {
  text-decoration: none;
  color: #223c6b;
  box-shadow: inset 0 -6em 0 0 #fff;
}

.button--border {
  background-color: transparent;
  border: 2px solid #223c6b;
  color: #223c6b;
}

.button--border:hover {
  text-decoration: none;
  color: #fff;
  box-shadow: inset 0 -6em 0 0 #223c6b;
}

.bgvideoWrap {
  overflow: hidden;
  position: relative;
  background: #223c6b;
  min-height: 500px;
  max-height: 500px;
}

@media (min-width: 33.125em) {
  .bgvideoWrap {
    min-height: 720px;
    max-height: 720px;
  }
}

.bgvideoWrap:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}

.bgvideoWrap .videoContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bgvideoWrap .videoContent .videoBgImg {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  background-size: cover;
  position: relative;
}

.bgvideoWrap .videoContent .videoBgImg .videoOverlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.bgvideoWrap .videoContent .videoBgImg .textContent {
  position: relative;
  z-index: 3;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  text-align: center;
  color: #fff;
  line-height: 1.3;
}

.bgvideoWrap .bgVideo {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.bgvideoWrap .bgVideo video {
  height: auto;
  left: 50%;
  min-height: 101%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
  max-width: unset !important;
}

.bgvideoWrap .sliderTextWrap {
  position: relative;
  z-index: 3;
  margin: 0 auto;
  max-width: 100%;
  width: 80%;
  text-align: center;
  color: #fff;
  line-height: 1.3;
}

.bgvideoWrap .sliderTextWrap .iconImageWrap {
  max-width: 128px;
  margin: 0 auto 20px;
}

@media (min-width: 40em) {
  .bgvideoWrap .sliderTextWrap .iconImageWrap {
    max-width: 256px;
  }
}

.bgvideoWrap .sliderTextWrap .content {
  margin-bottom: 20px;
}

.bgvideoWrap .sliderTextWrap .content .mainText {
  font-size: 30px;
  font-size: 1.875rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
}

@media (min-width: 50em) {
  .bgvideoWrap .sliderTextWrap .content .mainText {
    font-size: 48px;
    font-size: 3rem;
    margin-bottom: 15px;
  }
}

.bgvideoWrap .sliderTextWrap .content .mainText p {
  font-weight: 700;
}

.bgvideoWrap .sliderTextWrap .content .subText {
  font-size: 24px;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 300;
  margin-bottom: 30px;
}

@media (min-width: 50em) {
  .bgvideoWrap .sliderTextWrap .content .subText {
    font-size: 36px;
    font-size: 2.25rem;
  }
}

.bgvideoWrap .sliderTextWrap .content .subText p {
  font-weight: 300;
}

.bgvideoWrap .slideBtnWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bgvideoWrap .slideBtnWrap .sliderBtn {
  display: inline-block;
  margin: 5px;
  padding: 12px 20px 10px;
  font-size: 16px;
  font-size: 1rem;
  color: #fff;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  border-radius: 10px;
}

@media (min-width: 50em) {
  .bgvideoWrap .slideBtnWrap .sliderBtn {
    padding: 18px 40px 15px;
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.bgvideoWrap .slideBtnWrap .sliderBtn i {
  margin-left: 10px;
}

.bgvideoWrap .slideBtnWrap .sliderBtn.btnBgSolid {
  background: #223c6b;
}

.bgvideoWrap .slideBtnWrap .sliderBtn.btnBgSolid:hover {
  color: #223c6b;
  box-shadow: inset 0 -6em 0 0 #fff;
}

.bgvideoWrap .slideBtnWrap .sliderBtn.btnBgTransparent {
  background: transparent;
  border: 1px solid #fff;
}

.bgvideoWrap .slideBtnWrap .sliderBtn.btnBgTransparent:hover {
  box-shadow: inset 0 -6em 0 0 #fff;
  color: #223c6b;
}

.slideOverVideo .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
}

.slideOverVideo .owl-nav .owl-prev,
.slideOverVideo .owl-nav .owl-next {
  position: absolute;
  transform: translateY(-50%);
  margin-top: -10px;
  opacity: 0.7;
  transition: 0.3s all ease;
  border: 0;
  outline: none;
  color: #fff;
}

.slideOverVideo .owl-nav .owl-prev span,
.slideOverVideo .owl-nav .owl-next span {
  font-family: "Font Awesome 5 Free";
  font-size: 40px;
  color: #fff;
}

.slideOverVideo .owl-nav .owl-prev:hover,
.slideOverVideo .owl-nav .owl-next:hover {
  opacity: 1;
  color: #fff;
}

.slideOverVideo .owl-nav .owl-prev {
  left: 10px;
}

.slideOverVideo .owl-nav .owl-next {
  right: 10px;
}

.banner-heading {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 20px;
  font-weight: 700;
}

@media (min-width: 33.125em) {
  .banner-heading {
    font-size: 2rem;
  }
}

@media (min-width: 50em) {
  .banner-heading {
    font-size: 3rem;
  }
}

.page-section {
  padding: 50px 0;
}

@media (min-width: 50em) {
  .page-section {
    padding: 80px 0;
  }
}

.page-section--divider {
  position: relative;
}

.page-section--divider::before {
  position: absolute;
  width: 100px;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  content: "";
  display: block;
  height: 3px;
  background-color: #223c6b;
}

.page-section--bg-grey {
  background-color: rgba(141, 141, 141, 0.05);
}

.page-section--bg-black {
  background-color: #111111;
}

.page-section--no-b-padding {
  padding-bottom: 0;
}

.page-section--no-t-padding {
  padding-top: 0;
}

.page-section--no-padding {
  padding: 0;
}

.page-section--b-n-margin {
  margin-bottom: -1.5rem;
}

.page-section--blockchain {
  position: relative;
  background: linear-gradient(rgba(17, 17, 17, 0.8), rgba(17, 17, 17, 0.8)), url("../images/bg/block-chain-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.page-section--filmproduction {
  position: relative;
  background: linear-gradient(rgba(17, 17, 17, 0.8), rgba(17, 17, 17, 0.8)), url("../images/bg/flim-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.page-section--press {
  position: relative;
  background: linear-gradient(rgba(17, 17, 17, 0.8), rgba(17, 17, 17, 0.8)), url("../images/bg/press-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.page-section p {
  font-size: 1.125rem;
  margin-bottom: 20px;
}

@media (min-width: 50em) {
  .page-section p {
    font-size: 1.25rem;
  }
}

@media (min-width: 80.0625em) {
  .page-section p {
    font-size: 1.375rem;
  }
}

.page-section p:last-child {
  margin-bottom: 0;
}

.common-wrap {
  margin-top: 30px;
  text-align: center;
}

@media (min-width: 33.125em) {
  .common-wrap {
    margin-top: 40px;
  }
}

@media (min-width: 50em) {
  .common-wrap {
    margin-top: 50px;
  }
}

@media (min-width: 63.125em) {
  .common-wrap {
    margin-top: 60px;
  }
}

@media (min-width: 40em) {
  .books {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 50px;
  }
}

.book {
  position: relative;
  display: block;
  box-shadow: 0 0 10px 1px rgba(141, 141, 141, 0.5);
  margin-bottom: 50px;
  padding: 20px 20px 52px;
  text-decoration: none;
}

.book:last-of-type {
  margin-bottom: 0;
}

.book:hover {
  text-decoration: none !important;
}

.book:hover .book-image img {
  transform: scale(1.04);
}

@media (min-width: 40em) {
  .book {
    margin-bottom: 0;
    padding: 30px 20px 52px;
  }
}

.book .book-image {
  overflow: hidden;
}

.book .book-image img {
  transition: all 0.3s ease-in-out;
}

.book .book-heading {
  margin-top: 30px;
}

.book .about-book {
  margin: 30px 0;
}

.book .book-readmore {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.single-book {
  text-align: center;
  max-width: 1200px;
  margin: auto;
}

@media (min-width: 33.125em) {
  .single-book {
    box-shadow: 0 0 10px 1px rgba(141, 141, 141, 0.5);
    padding: 20px 30px 30px;
  }
}

@media (min-width: 50em) {
  .single-book {
    padding: 20px 50px 50px;
  }
}

@media (min-width: 50em) {
  .single-book {
    padding: 20px 80px 80px;
  }
}

.single-book .book-image {
  margin-bottom: 20px;
}

.single-book .about-book {
  padding: 30px 0;
}

.single-book .order-button {
  font-size: 1.5rem;
  padding: 15px 30px;
  text-transform: uppercase;
}

.achievement {
  max-width: 513px;
  margin: 50px auto;
}

.investments {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 20px;
}

@media (min-width: 33.125em) {
  .investments {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }
}

@media (min-width: 63.125em) {
  .investments {
    grid-template-columns: repeat(5, minmax(100px, 1fr));
    gap: 30px;
  }
}

.sector {
  box-shadow: 0 0 10px 1px rgba(141, 141, 141, 0.5);
  transition: all 0.3s ease-in-out;
  padding: 30px 10px 10px;
}

@media (min-width: 50em) {
  .sector {
    padding: 50px 10px 30px;
  }
}

.sector:hover {
  transform: translateY(-10px);
}

.sector .icon {
  max-width: 72px;
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
  margin: auto;
  background-color: #223c6b;
}

@media (min-width: 50em) {
  .sector .icon {
    max-width: 128px;
  }
}

.sector .text {
  padding: 20px 0;
  color: #111;
}

@media (min-width: 50em) {
  .sector .text {
    font-size: 1.125rem;
  }
}

@media (min-width: 63.125em) {
  .block-chain-wrap {
    display: flex;
    align-items: center;
    background: rgba(17, 17, 17, 0.8);
  }
}

.block-chain-wrap .block-chain-image {
  max-width: 300px;
  flex-basis: 100%;
  margin: auto;
}

@media (min-width: 63.125em) {
  .block-chain-wrap .block-chain-image {
    min-width: 300px;
    margin: unset;
  }
}

.block-chain-wrap .block-chain-text {
  text-align: center;
  padding-top: 30px;
}

@media (min-width: 63.125em) {
  .block-chain-wrap .block-chain-text {
    padding: 0 0 0 30px;
    text-align: left;
  }
}

.block-chain-wrap .block-chain-text .block-heading {
  margin-bottom: 20px;
}

@media (min-width: 63.125em) {
  .block-chain-wrap .block-chain-text .block-heading {
    text-align: left !important;
  }
}

.block-chain-wrap .block-chain-text .text p {
  color: #fff;
}

.block-chain-wrap .block-chain-text .button {
  text-align: left;
  margin-top: 20px;
}

.paradigm-btn-wrap {
  text-align: center;
  margin-top: 80px;
}

.paradigm-btn-wrap .button {
  margin: 5px;
}

.films {
  margin-bottom: 50px;
}

@media (min-width: 33.125em) {
  .films {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 50px;
  }
}

@media (min-width: 63.125em) {
  .films {
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    gap: 30px;
  }
}

.film {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
  transition: all 0.3s ease-in-out;
}

.film:hover {
  transform: translateY(-10px);
}

.film:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 33.125em) {
  .film {
    margin-bottom: 0;
  }
}

.film .film-content,
.film .film-content:hover {
  text-decoration: none;
}

.film .poster-img {
  margin-bottom: 20px;
}

.film .imdb-rating {
  margin-top: 15px;
  text-align: center;
}

.press-articles {
  padding-top: 30px;
}

@media (min-width: 33.125em) {
  .press-articles {
    padding-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 50px;
  }
}

@media (min-width: 50em) {
  .press-articles {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    gap: 80px;
  }
}

.press-article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  margin-bottom: 30px;
}

.press-article:last-child {
  margin-bottom: 0;
}

.press-article:hover {
  text-decoration: none;
  border: 2px solid rgba(255, 255, 255, 0.8);
}

@media (min-width: 33.125em) {
  .press-article {
    margin-bottom: 0;
  }
}

.press-article .press-logo {
  max-width: 250px;
  margin: auto;
}

.press-article .text {
  padding-top: 30px;
}

.press-article .text p {
  color: #fff;
}

.form-section {
  position: relative;
}

.sub-banner--bg {
  position: relative;
  background: linear-gradient(rgba(17, 17, 17, 0.8), rgba(17, 17, 17, 0.8)), url("../images/bg/press-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.breadcrumb {
  text-align: center;
  padding: 15px 15px;
  font-size: 1.125rem;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.breadcrumb a {
  color: #fff;
}

.breadcrumb span {
  padding: 0 10px;
  font-size: 1.25rem;
}

.page-heading {
  padding: 30px 15px;
}

@media (min-width: 50em) {
  .page-heading {
    padding: 50px 15px;
  }
}

.cv-block {
  border: 1px solid rgba(17, 17, 17, 0.2);
  padding: 20px;
}

@media (min-width: 63.125em) {
  .cv-block {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 87.5em) {
  .cv-block {
    padding: 0;
  }
}

.cv-block .cv-image {
  max-width: 300px;
  flex-basis: 100%;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
}

.cv-block .cv-image img {
  object-fit: cover;
}

@media (min-width: 80.0625em) {
  .cv-block .cv-image {
    min-width: 550px;
    margin: unset;
    border-radius: 0;
    flex: 1;
  }
}

.cv-block .cv-text {
  text-align: center;
  padding-top: 30px;
}

@media (min-width: 63.125em) {
  .cv-block .cv-text {
    padding: 0 30px 0 30px;
    text-align: left;
  }
}

.cv-block .cv-text .cv-social-media {
  text-align: center;
  margin-top: 20px;
}

@media (min-width: 63.125em) {
  .cv-block .cv-text .cv-social-media {
    text-align: left;
  }
}

.cv-block .cv-text .cv-social-media a {
  width: 36px;
  height: 36px;
  display: inline-block;
  margin: 3px;
  border: 1px solid rgba(17, 17, 17, 0.2);
  padding: 5px;
  text-align: center;
}

.cv-block .cv-text .cv-social-media a:hover {
  border: 1px solid rgba(17, 17, 17, 0.5);
}

.cv-block .cv-text .cv-social-media a i {
  font-size: 24px;
  line-height: 1.2;
}

.gallery-grid {
  column-count: 1;
  column-gap: 5px;
}

@media (min-width: 33.125em) {
  .gallery-grid {
    column-count: 2;
    gap: 5px;
  }
}

@media (min-width: 50em) {
  .gallery-grid {
    column-count: 3;
  }
}

@media (min-width: 80.0625em) {
  .gallery-grid {
    column-count: 4;
  }
}

.gallery-grid a {
  display: inline-block;
  margin: 0 0 5px;
  width: 100%;
}

.gallery-grid img {
  transition: 0.8s opacity;
}

.gallery-grid:hover img {
  opacity: 0.3;
}

.gallery-grid:hover img:hover {
  opacity: 1;
}

@media (min-width: 40em) {
  .publications {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 30px;
  }
}

@media (min-width: 80.0625em) {
  .publications {
    grid-template-columns: repeat(4, minmax(100px, 1fr));
  }
}

.publication {
  position: relative;
  box-shadow: 0 0 10px 1px rgba(141, 141, 141, 0.5);
  margin-bottom: 30px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 40em) {
  .publication {
    margin-bottom: 0;
  }
}

.publication:hover {
  text-decoration: none;
  box-shadow: 0 0 10px 1px #8d8d8d;
}

.publication .publication-date {
  font-size: 1.25rem;
  padding: 20px 20px 0;
}

.publication .publication-text {
  padding: 30px 20px;
}

.press-profiles {
  padding-top: 30px;
  border-top: 1px solid rgba(141, 141, 141, 0.5);
}

@media (min-width: 33.125em) {
  .press-profiles {
    padding-top: 40px;
  }
}

@media (min-width: 40em) {
  .press-profiles {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    gap: 30px;
  }
}

@media (min-width: 50em) {
  .press-profiles {
    padding-top: 50px;
  }
}

@media (min-width: 63.125em) {
  .press-profiles {
    padding-top: 60px;
  }
}

.press-profile {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  text-align: center;
  border: 1px solid rgba(141, 141, 141, 0.5);
  border-radius: 5px;
  margin-bottom: 30px;
}

@media (min-width: 40em) {
  .press-profile {
    margin-bottom: 0;
  }
}

@media (min-width: 63.125em) {
  .press-profile {
    padding: 20px 80px;
  }
}

.press-profile:hover {
  border: 1px solid #223c6b;
}

#loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 3;
  text-align: center;
  background-color: rgba(17, 17, 17, 0.3);
}

#loader img {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  margin-top: -16px;
}

#response_notification {
  max-width: 820px;
  margin: auto;
}

#response_notification .alert {
  padding: 8px 10px;
  margin-bottom: 30px;
  border-radius: 5px;
}

#response_notification .alert--success {
  background-color: rgba(43, 147, 3, 0.2);
}

#response_notification .alert--danger {
  background-color: rgba(255, 0, 0, 0.2);
}

.form-wrap {
  max-width: 820px;
  margin: auto;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

.form-wrap input[type="text"],
.form-wrap input[type="email"],
.form-wrap textarea {
  margin-bottom: 20px;
  max-width: 100%;
  width: 100%;
  border: 1px solid rgba(141, 141, 141, 0.5);
  font-weight: 300;
  border-radius: 3px;
  padding: 10px;
  font-size: 1.25rem;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  outline: none;
}

.form-wrap input[type="text"].error,
.form-wrap input[type="email"].error,
.form-wrap textarea.error {
  outline: 1px solid red;
}

.form-wrap textarea {
  min-height: 100px;
  resize: vertical;
}

.form-wrap textarea.error {
  outline: 1px solid red;
}

.form-wrap input[type="checkbox"] {
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-right: 15px;
  outline: 1px solid rgba(141, 141, 141, 0.5);
  vertical-align: text-top;
}

.form-wrap input[type="checkbox"].error {
  outline: 2px solid red;
}

.form-wrap p {
  text-align: left;
}

.form-wrap input[type="button"],
.form-wrap input[type="submit"] {
  width: auto;
  display: block;
  margin-top: 30px;
}

.form-wrap label.error {
  display: none !important;
  color: #ff0000;
  font-size: 1rem;
  margin-top: -5px;
  text-align: left;
}

@media (min-width: 63.125em) {
  .contact-wrap {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 0 10px 1px rgba(141, 141, 141, 0.5);
  }
}

.contact-tab {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.contact-tab:first-child {
  background-color: rgba(141, 141, 141, 0.1);
}

@media (min-width: 63.125em) {
  .contact-tab {
    max-width: 50%;
    flex-basis: 100%;
    padding: 30px;
  }
}

.address {
  padding: 30px 0 0 0;
}

.contact-social-icons {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

.contact-social-icons li {
  margin-right: 15px;
}

.contact-social-icons li:last-child {
  margin-right: 0;
}

.contact-social-icons li a {
  width: 32px;
  text-align: center;
  color: #8d8d8d;
}

.contact-social-icons li a:hover {
  color: #111;
}

.contact-social-icons li i {
  font-size: 1.5rem;
}

.contact-form-wrap {
  padding: 30px 0;
  border-top: 1px solid rgba(141, 141, 141, 0.3);
}

@media (min-width: 63.125em) {
  .contact-form-wrap {
    border-top: none;
  }
}

.contact-form-wrap .contact-form {
  max-width: 700px;
  padding: 30px 0 0;
  margin: auto;
}

@media (min-width: 87.5em) {
  .contact-form-wrap .contact-form {
    padding: 30px 30px 0;
  }
}

.legal-privacy-wrap {
  text-align: left;
  word-break: break-word;
}

@media (min-width: 63.125em) {
  .legal-privacy-wrap {
    margin-top: 50px;
  }
}

.legal-privacy-wrap h2 {
  font-size: 30px;
  font-size: 1.875rem;
  letter-spacing: 0.05rem;
  font-weight: 600;
  padding-bottom: 15px;
}

.legal-privacy-wrap h2--text-white {
  color: #fff;
}

@media (min-width: 50em) {
  .legal-privacy-wrap h2 {
    font-size: 36px;
    font-size: 2.25rem;
  }
}

@media (min-width: 63.125em) {
  .legal-privacy-wrap h2 {
    font-size: 46px;
    font-size: 2.875rem;
  }
}

.legal-privacy-wrap h3 {
  font-size: 20px;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
  font-weight: 600;
  padding-bottom: 15px;
}

.legal-privacy-wrap h3--text-white {
  color: #fff;
}

@media (min-width: 50em) {
  .legal-privacy-wrap h3 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

@media (min-width: 63.125em) {
  .legal-privacy-wrap h3 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

.legal-privacy-wrap ul {
  font-size: 1.125rem;
  margin: 20px 0;
  list-style-type: disc;
  padding-left: 25px;
}

@media (min-width: 50em) {
  .legal-privacy-wrap ul {
    font-size: 1.25rem;
  }
}

@media (min-width: 80.0625em) {
  .legal-privacy-wrap ul {
    font-size: 1.375rem;
  }
}

.legal-privacy-wrap a {
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(17, 17, 17, 0.8);
}

.legal-privacy-wrap a:hover {
  text-decoration: none;
  border-bottom: 1px solid rgba(17, 17, 17, 0);
}

.page-footer {
  background: #223c6b;
  padding: 20px 0;
}

@media (min-width: 63.125em) {
  .page-footer {
    padding: 40px 0;
  }
}

.footer-inner {
  display: flex;
  flex-direction: column;
}

@media (min-width: 63.125em) {
  .footer-inner {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.footer-nav ul {
  display: flex;
  flex-direction: column;
}

@media (min-width: 33.125em) {
  .footer-nav ul {
    flex-direction: row;
    justify-content: center;
  }
}

@media (min-width: 33.125em) {
  .footer-nav ul li:first-child a {
    padding-left: 0;
  }
}

.footer-nav ul li a {
  display: block;
  padding: 10px 0;
  text-decoration: none;
  color: #8d8d8d;
  border-bottom: 1px dashed #8d8d8d;
}

@media (min-width: 33.125em) {
  .footer-nav ul li a {
    padding: 0 8px;
    border-bottom: none;
  }
}

.footer-nav ul li a:hover {
  color: #fff;
}

.footer-social-icons {
  display: flex;
  margin-top: 30px;
}

@media (min-width: 33.125em) {
  .footer-social-icons {
    justify-content: center;
  }
}

@media (min-width: 63.125em) {
  .footer-social-icons {
    order: 3;
    margin-top: 0;
    justify-content: end;
  }
}

.footer-social-icons li {
  margin-right: 15px;
}

.footer-social-icons li:last-child {
  margin-right: 0;
}

.footer-social-icons li a {
  width: 32px;
  text-align: center;
  color: #8d8d8d;
}

.footer-social-icons li a:hover {
  color: #fff;
}

.footer-social-icons li i {
  font-size: 1.5rem;
}

.copyright {
  color: #8d8d8d;
  margin-top: 30px;
}

@media (min-width: 33.125em) {
  .copyright {
    text-align: center;
  }
}

@media (min-width: 63.125em) {
  .copyright {
    order: 2;
    margin-top: 0;
  }
}

.gotop-btn {
  width: 50px;
  height: 50px;
  background: rgba(17, 17, 17, 0.7);
  position: fixed;
  right: 20px;
  bottom: -100px;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  z-index: 5;
  transition: all 0.5s ease;
}

.gotop-btn.active {
  bottom: 50px;
}

.gotop-btn i {
  font-size: 1.5rem;
  line-height: 2;
  color: #fff;
}

*::-moz-selection {
  background: none repeat scroll 0 0 #1777af;
  color: #fafafa;
  text-shadow: none;
  font-weight: 300;
}

input:-moz-placeholder {
  font-size: 0.875em;
  line-height: 1.4;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
}

input[type="search"] {
  box-sizing: content-box;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0 none;
  padding: 0;
}

p {
  /*-moz-hyphens: auto;*/
}

@media print {
  * {
    background: none repeat scroll 0 0 transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }
  a, a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr, img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }
  h2, h3 {
    page-break-after: avoid;
  }
}
