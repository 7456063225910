.publications {
  @include atSmallMedium {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 30px;
  }
  @include atXlarge {
    grid-template-columns: repeat(4, minmax(100px, 1fr));
  }
}
.publication {
  position: relative;
  box-shadow: 0 0 10px 1px rgba($grey, 0.5);
  margin-bottom: 30px;

  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include atSmallMedium {
    margin-bottom: 0;
  }

  &:hover {
    text-decoration: none;
    box-shadow: 0 0 10px 1px rgba($grey, 1);
  }

  .publication-date {
    font-size: 1.25rem;
    padding: 20px 20px 0;
  }
  .publication-text {
    padding: 30px 20px;
  }
}

// press profile
.press-profiles {
  padding-top: 30px;
  border-top: 1px solid rgba($grey, 0.5);

  @include atSmall {
    padding-top: 40px;
  }

  @include atSmallMedium {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    gap: 30px;
  }

  @include atMedium {
    padding-top: 50px;
  }

  @include atLarge {
    padding-top: 60px;
  }
}

.press-profile {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  text-align: center;
  border: 1px solid rgba($grey, 0.5);
  border-radius: 5px;
  margin-bottom: 30px;

  @include atSmallMedium {
    margin-bottom: 0;
  }

  @include atLarge {
    padding: 20px 80px;
  }

  &:hover {
    border: 1px solid rgba($primary, 1);
  }
}
