.page-footer {
  background: $blue;
  padding: 20px 0;

  @include atLarge {
    padding: 40px 0;
  }
}
.footer-inner {
  display: flex;
  flex-direction: column;

  @include atLarge {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.footer-nav ul {
  display: flex;
  flex-direction: column;

  @include atSmall {
    flex-direction: row;
    justify-content: center;
  }

  li {
    &:first-child {
      @include atSmall {
        a {
          padding-left: 0;
        }
      }
    }

    a {
      display: block;
      padding: 10px 0;
      text-decoration: none;
      color: $grey;
      border-bottom: 1px dashed $grey;

      @include atSmall {
        padding: 0 8px;
        border-bottom: none;
      }

      &:hover {
        color: $white;
      }
    }
  }
}

.footer-social-icons {
  display: flex;
  margin-top: 30px;

  @include atSmall {
    justify-content: center;
  }

  @include atLarge {
    order: 3;
    margin-top: 0;
    justify-content: end;
  }

  li {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    a {
      width: 32px;
      // display: block;
      text-align: center;
      color: $grey;

      &:hover {
        color: $white;
      }
    }

    i {
      font-size: 1.5rem;
    }
  }
}

.copyright {
  color: $grey;
  margin-top: 30px;

  @include atSmall {
    text-align: center;
  }

  @include atLarge {
    order: 2;
    margin-top: 0;
  }
}

// gotop
.gotop-btn {
  width: 50px;
  height: 50px;
  background: rgba($black, 0.7);
  position: fixed;
  right: 20px;
  bottom: -100px;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  z-index: 5;
  transition: all 0.5s ease;

  &.active {
    bottom: 50px;
  }

  i {
    font-size: 1.5rem;
    line-height: 2;
    color: $white;
  }
}
