#loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 3;
  text-align: center;
  background-color: rgba($black, 0.3);

  img {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 50%;
    margin-top: -16px;
  }
}
#response_notification {
  max-width: 820px;
  margin: auto;

  .alert {
    padding: 8px 10px;
    margin-bottom: 30px;
    border-radius: 5px;

    &--success {
      background-color: rgba($success, 0.2);
    }
    &--danger {
      background-color: rgba($error, 0.2);
    }
  }
}

// form
.form-wrap {
  max-width: 820px;
  margin: auto;
  font-family: $body-font;

  input[type="text"],
  input[type="email"],
  textarea {
    margin-bottom: 20px;
    max-width: 100%;
    width: 100%;
    border: 1px solid rgba($grey, 0.5);
    font-weight: 300;
    border-radius: 3px;
    padding: 10px;
    font-size: 1.25rem;
    font-family: $body-font;
    outline: none;

    &.error {
      outline: 1px solid rgba($error, 1);
    }
  }
  textarea {
    min-height: 100px;
    resize: vertical;

    &.error {
      outline: 1px solid rgba($error, 1);
    }
  }
  input[type="checkbox"] {
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-right: 15px;
    outline: 1px solid rgba($grey, 0.5);
    vertical-align: text-top;

    &.error {
      outline: 2px solid rgba($error, 1);
    }
  }
  p {
    text-align: left;
  }
  input[type="button"],
  input[type="submit"] {
    width: auto;
    display: block;
    margin-top: 30px;
  }

  label.error {
    display: none !important;
    color: $error;
    font-size: 1rem;
    margin-top: -5px;
    text-align: left;
  }
}
