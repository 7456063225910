.hidden,.invisible{visibility:hidden}
.uppercase{text-transform:uppercase}
.lowercase{text-transform:lowercase}
.boldcase{font-weight:700}
.normalcase{font-weight:400}
.textdec-underline{text-decoration:underline}
.textdec-normal,a{text-decoration:none}
.Text-Idnt{text-indent:-99999px}
.hidden{display:none!important}
.visuallyhidden{border:0;clip:rect(0,0,0,0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus{clip:auto;height:auto;margin:0;overflow:visible;position:static;width:auto}
img{border:0;height:auto;max-width:100%}
img.floatleft{float:left;margin:0 10px 0 0}
img.floatright{float:right;margin:0 0 0 10px}
.floatleft{float:left}.floatright{float:right}
a{
	color:#000;-webkit-transition:all .5s ease-in-out;
	-moz-transition:all .5s ease-in-out;
	-o-transition:all .5s ease-in-out;
	transition:all .5s ease-in-out
}
a:hover{text-decoration:underline}
// p:last-child{padding-bottom:0!important}

.noPaddingTop{
	padding-top: 0 !important;
}
.noPaddingBottom{
	padding-bottom: 0 !important;
}
.noPaddingLeft{
	padding-left: 0 !important;
}
.noPaddingRight{
	padding-right: 0 !important;
}

@keyframes smooth-entry {
	0% {
	  opacity: 0;
	  -webkit-transform: translateY(-1.8em);
	  -moz-transform: translateY(-1.8em);
	  -ms-transform: translateY(-1.8em);
	  -o-transform: translateY(-1.8em);
	  transform: translateY(-1.8em);
	}
	100% {
	  opacity: 1;
	  -webkit-transform: translateY(0);
	  -moz-transform: translateY(0);
	  -ms-transform: translateY(0);
	  -o-transform: translateY(0);
	  transform: translateY(0);
	}
}
@keyframes smooth-entry-revers {
	0% {
	  opacity: 0;
	  -webkit-transform: translateY(1.8em);
	  -moz-transform: translateY(1.8em);
	  -ms-transform: translateY(1.8em);
	  -o-transform: translateY(1.8em);
	  transform: translateY(1.8em);
	}
	100% {
	  opacity: 1;
	  -webkit-transform: translateY(0);
	  -moz-transform: translateY(0);
	  -ms-transform: translateY(0);
	  -o-transform: translateY(0);
	  transform: translateY(0);
	}
}