.sub-banner {
  //   padding: 0 15px;
  &--bg {
    position: relative;
    background: linear-gradient(rgba($black, 0.8), rgba($black, 0.8)),
      url("../images/bg/press-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}
.breadcrumb {
  text-align: center;
  padding: 15px 15px;
  font-size: 1.125rem;
  color: $white;
  border-bottom: 1px solid rgba($white, 0.1);

  a {
    color: $white;

    // // REMOVE ME
    // &:nth-of-type(2) {
    //   display: none;
    // }
    // &:nth-of-type(3) {
    //   display: none;
    // }
  }
  span {
    padding: 0 10px;
    font-size: 1.25rem;

    // // REMOVE ME
    // &:nth-of-type(2) {
    //   display: none;
    // }
    // &:nth-of-type(3) {
    //   display: none;
    // }
  }
}
.page-heading {
  padding: 30px 15px;

  @include atMedium {
    padding: 50px 15px;
  }
}
