.site-header {
  background-color: $white;
  padding: 5px 20px;
  box-shadow: 0 0 5px 2px $grey;
  position: sticky;
  top: 0;
  z-index: 5;
}
// .site-header.fixed {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 5;
// }
.site-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  max-width: 230px;
  width: 100%;
  margin: 0 auto;
  // padding: 8px 0;

  @include atLarge {
    max-width: 350px;
    margin: 0;
    padding: 0;
  }
}
